import { useTranslation } from 'next-i18next';
import { Fragment, useCallback, useState } from 'react';
import classNames from 'classnames';
import { InvoiceStatusEnum, TaskStatusEnum } from '~/.generated/globalTypes';
import useUser from '~/contexts/User/useUser';
import SubmitButton from '~/features/forms/components/SubmitButton';
import useBookInvoice from '../hooks/useBookInvoice';
import useSendInvoice from '../hooks/useSendInvoice.ts';
import useUpdateInvoiceStatus from '../hooks/useUpdateInvoiceStatus';
import BookInvoiceButton from './BookInvoiceButton';
import SubmitInvoiceModal from './SubmitInvoiceModal';
import { InvoiceDetailsQuery_invoice_task } from './InvoiceDetails/graphql/.generated/InvoiceDetailsQuery';

type InvoiceStatusButtonsProps = {
  invoiceId: number;
  invoiceStatus: InvoiceStatusEnum;
  buttonClassName?: string;
  groupClassName?: string;
  invoiceTask: InvoiceDetailsQuery_invoice_task | null;
};

const statusMap = [
  {
    id: '1',
    source: InvoiceStatusEnum.DRAFT,
    target: InvoiceStatusEnum.SENT,
    icon: 'uil-arrow-right',
    text: 'buttons.send',
    adminOnly: false
  },
  {
    id: '2',
    source: InvoiceStatusEnum.SENT,
    target: InvoiceStatusEnum.PENDING,
    icon: 'uil-fast-mail',
    text: 'buttons.sendToEconomic',
    adminOnly: true
  },
  {
    id: '3',
    source: InvoiceStatusEnum.PENDING,
    target: InvoiceStatusEnum.SENT_TO_COMPANY,
    icon: 'uil-fast-mail',
    text: 'buttons.sendToCompany',
    adminOnly: true
  },
  {
    id: '4',
    source: InvoiceStatusEnum.SENT_TO_COMPANY,
    target: InvoiceStatusEnum.PAID,
    icon: 'uil-money-stack',
    text: 'buttons.paymentReceived',
    adminOnly: true
  },
  {
    id: '5',
    source: InvoiceStatusEnum.LATE_PAYMENT,
    target: InvoiceStatusEnum.PAID,
    icon: 'uil-money-stack',
    text: 'buttons.paymentReceived',
    adminOnly: true
  },
  {
    id: '6',
    source: InvoiceStatusEnum.SENT,
    target: InvoiceStatusEnum.DRAFT,
    icon: 'uil-arrow-left',
    text: 'buttons.unsend',
    adminOnly: false
  },
  {
    id: '7',
    source: InvoiceStatusEnum.SALARY_PAID_CUSTOMER_NOT_PAID,
    target: InvoiceStatusEnum.SALARY_PAID_CUSTOMER_PAID,
    icon: 'uil-money-stack',
    text: 'buttons.paymentReceived',
    adminOnly: false
  }
];

export default function InvoiceStatusButtons({
  invoiceId,
  invoiceStatus,
  buttonClassName,
  groupClassName,
  invoiceTask
}: InvoiceStatusButtonsProps): JSX.Element {
  const { t } = useTranslation('invoices');
  const { isAdmin, latestSentInvoiceDate, hasCompletedProfile } = useUser();

  const [showSubmitInvoiceModal, setShowSubmitInvoiceModal] = useState(false);
  const handleCloseSubmitModal = useCallback(() => {
    setShowSubmitInvoiceModal(false);
  }, []);

  const { updateInvoiceStatus, updating } = useUpdateInvoiceStatus({
    onCompleted: (data) => {
      if (latestSentInvoiceDate === null && data.updateInvoice.status === InvoiceStatusEnum.SENT && !isAdmin)
        setShowSubmitInvoiceModal(true);
    }
  });
  const { sendInvoice, updating: sending } = useSendInvoice();
  const { bookInvoice } = useBookInvoice();

  let filteredStatusMap = statusMap.filter(
    ({ source, adminOnly }) => invoiceStatus === source && (isAdmin || !adminOnly)
  );

  // prevent freelancers from submitting invoices if they haven't completed their profile or the task is still draft
  const canSubmitInvoice = !isAdmin && hasCompletedProfile && invoiceTask?.status !== TaskStatusEnum.DRAFT;
  if (!isAdmin && !canSubmitInvoice) {
    filteredStatusMap = filteredStatusMap.filter(({ target }) => target !== InvoiceStatusEnum.SENT);
  }

  const [amount, setAmount] = useState<string>();
  return (
    <>
      {filteredStatusMap.map(({ target, icon, text, id }) => (
        <Fragment key={id}>
          {(target === InvoiceStatusEnum.PAID || target === InvoiceStatusEnum.SALARY_PAID_CUSTOMER_PAID) && (
            <div className={classNames(groupClassName, 'input-group w-auto')}>
              <input
                className="form-control"
                style={{ width: 100 }}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
              <span className="input-group-text">dkk</span>
              <SubmitButton
                icon={<i className={`uil ${icon}`} />}
                onClick={() => updateInvoiceStatus(invoiceId, target, amount ?? undefined)}
                title={t(text)}
                saving={updating}
              />
            </div>
          )}
          {target !== InvoiceStatusEnum.PAID && (
            <SubmitButton
              icon={<i className={`uil ${icon}`} />}
              className={buttonClassName}
              onClick={() => updateInvoiceStatus(invoiceId, target, amount ?? undefined)}
              title={t(text)}
              saving={updating}
            />
          )}
        </Fragment>
      ))}
      {invoiceStatus === InvoiceStatusEnum.PENDING && isAdmin && (
        <BookInvoiceButton
          onConfirm={(sendViaEmail, sendViaEAN) => bookInvoice({ id: invoiceId, sendViaEmail, sendViaEAN })}
          className={buttonClassName}
        />
      )}
      {invoiceStatus === InvoiceStatusEnum.PENDING && isAdmin && (
        <SubmitButton
          icon={<i className="uil uil-fast-mail" />}
          className={buttonClassName}
          onClick={() => sendInvoice(invoiceId)}
          title="Re-send to economic"
          saving={sending}
        />
      )}
      <SubmitInvoiceModal onClose={handleCloseSubmitModal} show={showSubmitInvoiceModal} />
    </>
  );
}
